import { computed } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import config from '../../config';
import type { ProductVariant } from '~/utils/ct/types/product/ProductVariant';
import {
  useCartExtended,
  useStock,
  useUiNotification,
  useStoreVsfExtended,
  useCartSidebars,
} from '~/composables';
import { NotificationStatus } from '~/types/components/Notification/NotificationStatus';
import { isTestProduct } from '~/helpers/product/isTestProduct';
import { getLineItemFromSku } from '~/helpers/cart/getLineItemFromSku';

type AddToCartParams = {
  product: ProductVariant;
  quantity: number;
  isWithExtraGuarantee?: boolean;
  triggeredIn?: string;
  shouldOpenCart?: boolean;
};

/**
 * Custom composable for adding product to cart from add to cart button
 */
export const useAddToCart = (slug: string, sku: string) => {
  const { i18n } = useVSFContext();
  const { error: cartError, cart, addItem, addExtraGuaranteeProduct } = useCartExtended();
  const { openCartSidebars } = useCartSidebars();
  const { send: sendUiNotification } = useUiNotification();
  const { stocks: stocksFetched } = useStock(slug);
  const { isExtraGuaranteeEnabled } = useStoreVsfExtended();

  const stock = computed(() => stocksFetched.value?.[0]?.availableQuantity || 0);

  const quantityInCart = computed(() => {
    return getLineItemFromSku(cart.value, sku)?.quantity || 0;
  });

  const currentlyRemainingStock = computed(() => {
    return stock.value - quantityInCart.value;
  });

  const maxSingleProductQuantity = computed(() => {
    return config.MAX_SINGLE_PRODUCT_CART_QUANTITY - quantityInCart.value;
  });

  const availableQuantity = computed(() => {
    return Math.min(maxSingleProductQuantity.value, currentlyRemainingStock.value);
  });

  const isQuantitySelectionPossible = computed(() => {
    return availableQuantity?.value > 0 || (sku && isTestProduct(sku));
  });

  const addToCartButtonText = computed(() => {
    return isQuantitySelectionPossible.value ? 'Add to cart' : 'out-of-stock';
  });

  const addToCart =
    async ({ product, quantity, isWithExtraGuarantee, triggeredIn, shouldOpenCart }: AddToCartParams) => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      await addItem({
        product,
        quantity,
        triggeredIn,
      });
      if (isExtraGuaranteeEnabled.value && isWithExtraGuarantee) {
        await addExtraGuaranteeProduct(sku);
      }

      if (!shouldOpenCart) {
        openCartSidebars({ product, sku, isWithExtraGuarantee });
      }

      checkForErrors();
    };

  const hasAddToCartError = computed(() => !!cartError.value.addItem);

  const checkForErrors = () => {
    if (hasAddToCartError.value) {
      sendUiNotification({
        message: i18n.t('There was a problem with adding product to the cart. Please try again.'),
        id: 'cart-adding-problem',
        type: NotificationStatus.error,
        dismissible: true,
      });
    }
  };

  return {
    addToCart,
    availableQuantity,
    isQuantitySelectionPossible,
    addToCartButtonText,
    stock,
  };
};

export default useAddToCart;
