import { sharedRef, useVSFContext } from '@vue-storefront/core';
import type { Ref } from '@nuxtjs/composition-api';
import { computed, ref } from '@nuxtjs/composition-api';
import { useI18n } from '~/composables';
import { currencyCodes } from '~/i18n/enums/currencyCodes';
import { countryCodes } from '~/i18n';
import { languageAndCountryCodes } from '~/i18n/enums/languageAndCountryCodes';
import {
  EXTRA_GUARANTEE_ATTRIBUTES,
  EXTRA_GUARANTEE_PRICE,
} from '~/constants/extraGuarantee';
import getProductAttribute from '~/helpers/product/getProductAttribute/getProductAttribute';
import { useProductExtraGuarantee } from '~/composables/stores/useProductExtraGuarantee';

export const useExtraGuaranteePrice = () => {
  const { countryLocalization } = useI18n();
  const { i18n } = useVSFContext();
  const {
    productExtraGuarantee: extraGuaranteeProduct,
  } = useProductExtraGuarantee();

  const extraGuaranteePrice: Ref<number | null> = sharedRef(null, EXTRA_GUARANTEE_PRICE);
  const extraGuaranteePercentage: Ref<number | null> = ref(null);

  const getExtraGuaranteePercentage = (): typeof extraGuaranteePercentage['value'] => {
    if (!extraGuaranteeProduct.value) {
      extraGuaranteePercentage.value = null;
      return null;
    }

    const percentageString = getProductAttribute(
      extraGuaranteeProduct.value, EXTRA_GUARANTEE_ATTRIBUTES.EXTRA_GUARANTEE_RATE,
    );
    extraGuaranteePercentage.value = parseFloat(percentageString) || null;
    return extraGuaranteePercentage.value;
  };

  const setExtraGuaranteePrice = (productPrice: number) => {
    const percentage = getExtraGuaranteePercentage();
    if (!percentage) {
      extraGuaranteePrice.value = null;
      return;
    }

    const extraGuaranteeRatio = percentage / 100;
    extraGuaranteePrice.value = productPrice * extraGuaranteeRatio;
  };

  const extraGuaranteePriceFormatted = computed(() => {
    if (!extraGuaranteePrice.value) {
      return null;
    }

    const price = extraGuaranteePrice.value;
    let formattedPrice = i18n.n(price, 'currency');
    if (countryLocalization.value.countryCode === countryCodes.HU &&
      countryLocalization.value.currencyCode === currencyCodes.HUF) {
      const roundedUpPrice = price !== null ? Math.ceil(price) : 0;
      formattedPrice = roundedUpPrice.toLocaleString(languageAndCountryCodes.HU_HU,
        { style: 'currency', currency: currencyCodes.HUF, maximumFractionDigits: 0 });
    }
    return formattedPrice;
  });

  return {
    extraGuaranteePrice,
    extraGuaranteePriceFormatted,
    getExtraGuaranteePercentage,
    setExtraGuaranteePrice,
  };
};
