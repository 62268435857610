import { PRODUCT_ATTRIBUTES } from '~/constants/products';
import { extractLocalisedAttributes } from '~/helpers/product/extractLocalisedAttributes';
import { getProductIsOnStock } from '~/helpers/product/getProductStockInfo/getProductStockInfo';
import { productTag } from '~/types/product/attribute/ProductTag';
import type { ComparisonProductData } from '~/types/product/ComparisonProducts';
import type { ProductVariantExtended } from '~/types/product/ProductVariantExtended';

const SCORE_ON_STOCK = 3;
const SCORE_POPULAR = 2;
const SCORE_ON_SALE = 1;

const calculateProductScore = (productsData: ComparisonProductData): number => {
  const isOnStock = getProductIsOnStock(productsData.product);
  const isPopular = productsData.attributesLocalized.tags?.includes(productTag.Popular);
  const isOnSale = productsData.attributesLocalized.tags?.includes(productTag.Sale);

  let score = 0;
  if (isOnStock) score += SCORE_ON_STOCK;
  if (isPopular) score += SCORE_POPULAR;
  if (isOnSale) score += SCORE_ON_SALE;
  return score;
};

const sortComparisonProducts = (productsData: ComparisonProductData[]): void => {
  productsData.forEach(productData => {
    productData.score = calculateProductScore(productData);
  });

  productsData.sort((productDataA, productDataB) => {
    const scoreA = productDataA.score ?? 0;
    const scoreB = productDataB.score ?? 0;
    return scoreB - scoreA;
  });
};

const extractAttributesForEachProduct = (
  products: ProductVariantExtended[],
  languageAndCountry: string,
): ComparisonProductData[] => {
  return products.map(product => {
    const attributesLocalized = extractLocalisedAttributes(
      product,
      [
        PRODUCT_ATTRIBUTES.TECH_DETAILS,
        PRODUCT_ATTRIBUTES.TAGS,
      ],
      languageAndCountry,
    );

    return {
      product,
      attributesLocalized,
    };
  });
};

export const getComparisonProductsData = (
  products: ProductVariantExtended[],
  languageAndCountry: string,
): ComparisonProductData[] => {
  const productsData = extractAttributesForEachProduct(products, languageAndCountry);
  sortComparisonProducts(productsData);
  return productsData;
};
