import { ref } from '@nuxtjs/composition-api';
import type { Ref } from '@nuxtjs/composition-api';
import config from '~/config';
import { useI18n, useReviewConsentStore as useReviewConsentVuex, useCartExtended } from '~/composables';

export const useReviewConsent = () => {
  const { countryCode } = useI18n();
  const { attachReviewConsent } = useCartExtended();
  const reviewCountries = config.reviewConsentCountries as string[];

  const showReviewCheckbox: Ref<boolean> = ref(reviewCountries.includes(countryCode.value));

  const { reviewConsent, setReviewConsent } = useReviewConsentVuex();

  const setConsentForReview = (value: boolean): void => {
    setReviewConsent(!value);
    attachReviewConsent(!value);
  };

  const setDefaultConsentForReview = (): void => {
    if (!showReviewCheckbox.value) return;
    setReviewConsent(true);
    attachReviewConsent(true);
  };

  return {
    reviewConsent,
    setConsentForReview,
    showReviewCheckbox,
    setDefaultConsentForReview,
  };
};

export default useReviewConsent;
