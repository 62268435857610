import { computed, ref } from '@nuxtjs/composition-api';
import type { ProductVariant } from '~/utils/ct/types/product/ProductVariant';
import { useI18n, usePrefix, useProductDetailsCombined } from '~/composables';
import extendProducts from '~/helpers/product/extendProducts';
import type { ProductVariantGql } from '~/types/product/ProductVariantGql';
import { getProductSearchParams } from '~/helpers/product/getProductSearchParams';
import { CUSTOM_QUERIES } from '~/constants/customQueries';
import { useProduct } from '~/utils/ct';
import { COMPARISON_PRODUCTS_FETCH_LIMIT, COMPARISON_PRODUCTS_DISPLAY_LIMIT } from '~/constants/comparisonProducts';
import { getProductComparisonFilter } from '~/helpers/commercetools/graphql/getProductComparisonFilter';
import type { ProductVariantExtended } from '~/types/product/ProductVariantExtended';
import { getComparisonProductsData } from '~/helpers/product/getComparisonProductsData';
import type { ComparisonProductData } from '~/types/product/ComparisonProducts';

export default function (cacheId: string) {
  const {
    products: oldProducts,
    search: oldSearch,
  } = useProduct<ProductVariantGql>(`comparison-${cacheId}`);
  const { languagePrefix } = usePrefix();
  const { languageAndCountry, countryCode } = useI18n();
  const { combineTechnicalDetails } = useProductDetailsCombined();

  const products = ref<ProductVariantGql[]>([]);

  const extendedProducts = computed<ProductVariantExtended[]>(() => {
    const publishedProducts = extendProducts(
      products.value, languageAndCountry.value, countryCode.value, { isPublished: true });
    return publishedProducts || [];
  });

  const search = async (product: ProductVariant) => {
    products.value = [];
    const comparisonFiler = getProductComparisonFilter(product);
    if (!comparisonFiler) {
      return;
    }

    const productSearchParams = getProductSearchParams(
      {
        limit: COMPARISON_PRODUCTS_FETCH_LIMIT,
        customFilters: comparisonFiler,
      },
      { languageAndCountry: languageAndCountry.value, languagePrefix },
    );

    await oldSearch({
      ...productSearchParams,
      customQuery: CUSTOM_QUERIES.PRODUCT_SEARCH.productComparison,
    });
    products.value = oldProducts.value?.data?.products?.results || [];
  };

  const comparisonProductsData = computed<ComparisonProductData[]>(() => {
    const productsData = getComparisonProductsData(extendedProducts.value, languageAndCountry.value);
    const limitedProductData = productsData.slice(0, COMPARISON_PRODUCTS_DISPLAY_LIMIT);

    limitedProductData.forEach(productData => {
      combineTechnicalDetails(productData.product, productData.attributesLocalized);
    });

    return limitedProductData;
  });

  return {
    productsData: comparisonProductsData,
    search,
  };
}
