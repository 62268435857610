

























import { defineComponent } from '@nuxtjs/composition-api';
import { trustpilotMicroComboWidgetId } from '~/config';
import { useTrustPilotWidget } from '~/composables';
import TrustpilotMicroCombo from '~/components/atoms/TrustPilot/TrustpilotMicroCombo.vue';
import PhoneNumber from '~/components/atoms/PhoneNumber.vue';
import { PHONE_NUMBER_KEYS } from '~/constants/phoneNumber';

export default defineComponent({
  name: 'TrustpilotHeader',
  components: {
    TrustpilotMicroCombo,
    PhoneNumber,
  },
  props: {
    displayTrustpilot: {
      type: Boolean,
      default: false,
    },
  },
  setup () {
    const { showTrustPilotInHeader } = useTrustPilotWidget();

    return {
      trustpilotMicroComboWidgetId,
      showTrustPilotInHeader,
      PHONE_NUMBER_KEYS,
    };
  },
});

