import { useUser } from '@vsf-enterprise/commercetools';
import type { Customer } from '@vsf-enterprise/commercetools-types';
import type { CustomQuery, UseUserErrors, UseUserLoginParams, UseUserRegisterParams } from '@vue-storefront/core';
import type { Ref } from '@nuxtjs/composition-api';
import { onMounted, ref, watch, computed } from '@nuxtjs/composition-api';
import { useVSFContext, Logger } from '@vue-storefront/core';
import { useCartExtended, useI18n, useIntegrations } from '~/composables';

type ErrorState = Partial<Record<keyof UseUserErrors, null | Error>>;

export default function () {
  const { $auth } = useVSFContext();
  const { $cia } = useIntegrations();
  const {
    load,
    user,
    logout,
    login,
    updateUser,
    register,
    changePassword,
    isAuthenticated,
    loading,
    error: oldError,
  } = useUser();
  const { languageAndCountry } = useI18n();
  const { updateCustomerEmail, clearIfOtherStoreCart: clearCartIfOtherStoreCart } = useCartExtended();

  // Computed property to ensure the oldError type matches ErrorState
  const oldErrorWithCorrectType = computed(() => oldError.value as ErrorState);

  // Initializing error state with the correct type as @vue-storefront type wasn't correct
  const error = ref<ErrorState>(oldErrorWithCorrectType.value);

  const updateCustomer = async (customer: Partial<Customer>, sentEventToCia: boolean) => {
    if (customer) {
      ciaCustomerUpdate(customer, sentEventToCia);
      await updateCustomerEmail(customer.email, 'updateCustomer');
    }
  };

  const ciaCustomerUpdate = (customer: Partial<Customer>, sentEventToCia: boolean) => {
    try {
      $cia.mutations.setUserId(customer.id);
    } catch (error) {
      Logger.error(`cia|setUserId error: ${error}`);
    }
    /** running an event for registering in CIA that user has changed data */
    if (sentEventToCia) {
      try {
        $cia.event.customerUpdate(customer.id);
      } catch (error) {
        Logger.error(`cia|customerUpdate error: ${error}`);
      }
    }
  };

  async function newLoad () {
    await load();
    await updateCustomer(user.value, false);
  }

  async function newLogin (params: { userData: UseUserLoginParams, customQuery?: CustomQuery }) {
    await login({ user: params.userData, customQuery: params.customQuery });
    await clearCartIfOtherStoreCart();
    await updateCustomer(user.value, true);
  }

  async function newLogout () {
    await logout();
    await $auth.logout();
    try {
      $cia.mutations.setEmail('');
      $cia.mutations.setUserId('');
    } catch (error) {
      Logger.error(`cia|Error while registering logout event: ${error}`);
    }
  }

  async function newUpdateUser (params: { updatedUserData: any, customQuery?: CustomQuery }) {
    await updateUser({ user: params.updatedUserData, customQuery: params.customQuery });
    await updateCustomer(user.value, true);
  }

  async function newRegister (params: { userData: UseUserRegisterParams, customQuery?: CustomQuery }) {
    const userData = {
      ...params.userData,
      locale: languageAndCountry.value,
    };
    await register({
      user: userData,
      customQuery: params.customQuery,
    });
    await updateCustomer(user.value, true);
  }

  function resetError() {
    const errorKeys = Object.keys(error.value);
    errorKeys.forEach((errorKey) => {
      if (isErrorKeyValid(errorKey, error.value)) {
        error.value[errorKey] = null;
      }
    });
  }

  function isErrorKeyValid(errorKey: string, errorObject: ErrorState): errorKey is keyof ErrorState {
    return errorKey in errorObject;
  }

  watch(oldError, (newVal) => {
    error.value = newVal;
  }, { deep: true });

  onMounted(() => {
    resetError();
  });

  return {
    // TODO - revert syntax once fix is pushed for type incoherence between composables and getters
    //  https://expondo.atlassian.net/browse/INSP-988
    user: user as Ref<Customer>,
    load: newLoad,
    logout: newLogout,
    updateUser: newUpdateUser,
    register: newRegister,
    login: newLogin,
    isAuthenticated,
    changePassword,
    loading,
    error,
    resetError,
  };
}
