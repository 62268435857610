












import { defineComponent } from '@nuxtjs/composition-api';
import ExternalLink from '~/components/atoms/Link/ExternalLink/ExternalLink.vue';
import { LINK_TYPE_HREF } from '~/constants/html';

export default defineComponent({
  name: 'PhoneNumber',
  components: {
    ExternalLink,
  },
  props: {
    showIcon: {
      type: Boolean,
      default: true,
    },
    phoneNumber: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {
      LINK_TYPE_HREF,
    };
  },
});
