import { AxiosResponse } from 'axios';
import { SeoRedirectionIntegration } from '~/types/integrations/seoRedirection/SeoRedirectionIntegration';

export default (instance: SeoRedirectionIntegration) => {
  const { axios } = instance.services;

  return async (sourceUrl: string): Promise<AxiosResponse> => {
    const params = { sourceUrl };
    const returnData = await axios.get('/', { params });
    return returnData;
  };
};
