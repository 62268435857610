import { computed } from '@nuxtjs/composition-api';
import type{ ComputedRef, Ref } from '@nuxtjs/composition-api';
import type { ProductVariant } from '~/utils/ct/types/product/ProductVariant';
import { getFinalPrice } from '~/helpers/product/getFinalPrice';
import { getProductPriceAfterDiscounts, getProductPriceBeforeDiscounts } from '~/helpers/product/getPriceInfo';
import { ProductProcessedAttributes } from '~/types/product/attribute/ProductProcessedAttributes';

type PriceInformationParameters = ComputedRef<number | null>;

export interface PriceInformation {
  productPrice: PriceInformationParameters,
  discountedPrice: ComputedRef<Maybe<number>>,
  priceAfterDiscounts: PriceInformationParameters,
  finalPrice: PriceInformationParameters,
  priceBeforeDiscounts: PriceInformationParameters,
}

export default function () {
  const getPriceInformation = (
    product: Ref<ProductVariant>,
    localisedAttributes: Ref<ProductProcessedAttributes>,
  ): PriceInformation => {
    const priceBeforeDiscounts = computed(() => {
      return getProductPriceBeforeDiscounts(product.value, { attributesLocalized: localisedAttributes.value });
    });

    const priceAfterDiscounts = computed(() => getProductPriceAfterDiscounts(product.value));
    const productPrice = computed(() => priceBeforeDiscounts.value || priceAfterDiscounts.value);
    const discountedPrice = computed(() => priceBeforeDiscounts.value ? priceAfterDiscounts.value : undefined);
    const finalPrice = computed(() => getFinalPrice(discountedPrice.value, productPrice.value));

    return {
      priceBeforeDiscounts,
      priceAfterDiscounts,
      productPrice,
      discountedPrice,
      finalPrice,
    };
  };

  return {
    getPriceInformation,
  };
}
