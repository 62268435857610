module.exports = {
  VAT_NUMBER_LABEL: 'vatNumber',
  ADDRESS_CUSTOM_FIELDS: 'addressCustomFields',
  ALL_CATEGORIES: 'all-categories',
  FACET_SORTS: {
    LATEST: 'latest',
  },
  PRODUCT_VARIANT_ID: 1,
  VAT_VALIDATED_LABEL: 'vatValidated',
  ONSALE_PRODUCT_GRAPHQL_CUSTOM_FILTER: ` and masterData(current(masterVariant(
    (
      attributes(
        name = "standardPrice" and
        value(:LANGUAGE_AND_COUNTRY is defined) and
        value(:LANGUAGE_AND_COUNTRY != "")
      ) or
      prices(
        country = ":COUNTRY_CODE" and
        discounted is defined
      )
    ) and
    attributes(
      name = "isBware" and
      value = false
    )
  )))`,
  BWARE_PRODUCT_GRAPHQL_CUSTOM_FILTER: ` and masterData(current(masterVariant(
    attributes(name = "isBware" and value = true)
  )))`,
  EXCLUDE_UNSELLABLE_PRODUCT_CUSTOM_FILTER: `masterData(current(masterVariant(attributes(name="eligibleForSelling"
  and value(:LANGUAGE_AND_COUNTRY="true")))))`,
  KEY_IN_GRAPHQL_CUSTOM_FILTER: ' and key in (:KEY_LIST)',
  KEY_IS_NOT_DEFINED: 'key is not defined',
  RELATED_PRODUCT_LIMIT: 8,
  COMPLEMENTARY_PRODUCTS_LIMIT: 4,
  VAT_RAW_RESPONSE: 'vatValidationRawResponse',
  STORE_CUSTOM_FIELDS: {
    EXTRA_GUARANTEE_ENABLED: 'sale_of_additional_guarantee_enabled',
    VAT_VALIDATION_ENABLED: 'vat_validation_enabled',
    CHANGE_TAX_RATE_ENABLED: 'change_tax_rate_enabled',
    AUTOCOMPLETE_ENABLED: 'google_autocomplete_enabled',
    TRUSTPILOT_IN_HEADER_ENABLED: 'trustpilot_in_header_enabled',
    CAMPAIGNS: 'ex_store_campaigns',
    COMPLEMENTARY_PRODUCTS_ENABLED: 'complementary_products_enabled',
  },
  CATEGORY_CUSTOM_FIELDS: {
    IMG_URL: 'CategoryImageURL',
    HAS_PRODUCTS: 'hasProducts',
    CATEGORY_CONTENT_HTML: 'CategoryContentHTML',
    CATEGORY_POSTS: 'categoryPosts',
    RELATED_CATEGORIES_1: 'related_categories_1',
    RELATED_CATEGORIES_2: 'related_categories_2',
    RELATED_CATEGORIES_3: 'related_categories_3',
  },
  DISCOUNT_FIELDS: {
    STORES: 'stores',
    IS_ACTIVE: 'isActive',
    SORT_ORDER: 'sortOrder',
    VOLUME_DISCOUNT_GROUP: 'volumeDiscountGroup',
  },
  CAMPAIGN_INDICATOR: 'campaign:',
};
