import { computed } from '@nuxtjs/composition-api';
import { TRUSTPILOT_SUPPORTED_LANGUAGES } from '~/config/shared';
import { useI18n, useStoreVsfExtended } from '~/composables';

export default function () {
  const { language } = useI18n();
  const { isTrustpilotInHeaderEnabled } = useStoreVsfExtended();

  const showTrustPilotWidget = computed(() => language.value &&
    TRUSTPILOT_SUPPORTED_LANGUAGES.includes(language.value),
  );

  const showTrustPilotInHeader = computed(() => showTrustPilotWidget.value && isTrustpilotInHeaderEnabled.value);

  return {
    showTrustPilotWidget,
    showTrustPilotInHeader,
  };
}
