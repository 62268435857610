import { Logger, useVSFContext } from '@vue-storefront/core';
import { ref } from '@nuxtjs/composition-api';
import type { Category } from '@vsf-enterprise/commercetools-types';
import type { SiblingCategory } from '~/types/product/ProductCategories';
import { useI18n } from '~/composables';
import { ProductVariantExtended } from '~/types/product/ProductVariantExtended';
import { CUSTOM_QUERIES } from '~/constants/customQueries';
import { CATEGORY_TREE } from '~/constants/categoryTree';
import { removeEmptyCategories } from '~/helpers/category/categoriesTree';

export const useSiblingCategories = () => {
  const { $ct } = useVSFContext();
  const { languageAndCountry } = useI18n();

  const siblingCategories = ref<Category[]>([]);

  const filterCategoriesExcludingIndustry = (categories: Category[]) =>
    categories.filter((category) => category.ancestors?.[0]?.key !== CATEGORY_TREE.INDUSTRY.key);

  const getLastCategorySlug = (categories: Category[]) => {
    if (!categories.length) return null;
    const lastCategory = categories.at(-1);
    return lastCategory?.slug || null;
  };

  const getLastAncestorSlug = (categories: Category[]) => {
    if (!categories.length) return null;
    const lastCategory = categories.at(-1);
    const lastAncestor = lastCategory?.ancestors?.at(-1) || null;
    return lastAncestor?.slug || null;
  };

  const isSiblingCategory = (category: Category | SiblingCategory) => {
    return typeof category.slug === 'string' && typeof category.name === 'string';
  };

  const load = async (product: ProductVariantExtended) => {
    if (!product._categories || !product._categories[0]) {
      Logger.error('error.useSiblingCategories', 'Product categories are missing.');
      return;
    }

    const filteredCategories = filterCategoriesExcludingIndustry(product._categories);

    const lastAncestorSlug = getLastAncestorSlug(filteredCategories) || {};
    const lastCategorySlug = getLastCategorySlug(filteredCategories) || {};
    if (!lastAncestorSlug || !lastCategorySlug) {
      return;
    }

    try {
      const categoryResponse = await $ct.api.getCategory(
        { slug: lastAncestorSlug, acceptLanguage: languageAndCountry.value },
        { categories: CUSTOM_QUERIES.CATEGORIES.SIBLING },
      );
      const siblingCategoriesResponse = categoryResponse?.data?.categories?.results?.[0].children;
      if (siblingCategoriesResponse.length) {
        siblingCategories.value = siblingCategoriesResponse.filter((child: Category) => {
          const isNotEmpty = removeEmptyCategories([child], languageAndCountry.value).length > 0;
          return isNotEmpty && isSiblingCategory(child) && child.slug !== lastCategorySlug;
        });
      }
    } catch (err) {
      Logger.error('error.useSiblingCategories', String(err));
    }
  };

  return {
    siblingCategories,
    load,
  };
};
