import { Logger } from '@vue-storefront/core';
import { ref } from '@nuxtjs/composition-api';
import { AxiosError } from 'axios';
import { GrahamProductReview } from '~/types/product/attribute/GrahamProductReview';
import { ProductReviewError } from '~/types/product/attribute/ProductReview';
import { useIntegrations } from '~/composables';

export const useManageReviews = () => {
  const { $graham } = useIntegrations();

  const error = ref<{ addReview : AxiosError | null }>({ addReview: null });
  const errorMessage = ref('');
  const loading = ref<Boolean | undefined>(undefined);

  const addReview = async (productReview: GrahamProductReview) => {
    loading.value = false;
    try {
      await $graham.addReview(productReview);
      loading.value = true;
    } catch (e) {
      const err = e as AxiosError;
      if (err) {
        error.value.addReview = err;
        const communicate = err.response?.data?.communicate;
        if (
          communicate &&
          Object.values(ProductReviewError).includes(communicate)
        ) {
          errorMessage.value = communicate;
        } else {
          errorMessage.value = 'Something went wrong on our side. Please try again or contact support';
        }
      }
      Logger.error('Error during adding new review: ' + e);
    }
  };

  return {
    addReview,
    error,
    errorMessage,
    loading,
  };
};
