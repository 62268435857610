import type { ProductsSearchFilter, ProductsSearchParamsExtended } from '~/types/product/ProductSearch';
import { RELATED_PRODUCT_LIMIT } from '~/constants/commerceTools';
import { attributeType } from '~/utils/ct/types';
import type { ProductsSearchParams, ProductWhereSearch } from '~/utils/ct';
import { StringBoolean } from '~/types/StringBoolean';
import { excludeUnsellableProduct } from '~/helpers/commercetools/graphql/excludeUnsellableProduct';
import type { ProductsSearchCustomParams } from '~/types/product/ProductsSearchCustomParams';
import { PRODUCT_ATTRIBUTES } from '~/constants/products';

export const getProductSearchParams = (
  searchParams: ProductsSearchParams | ProductWhereSearch,
  customParams: ProductsSearchCustomParams,
): ProductsSearchParamsExtended => {
  const storeFilter: ProductsSearchFilter = {
    type: attributeType.LOCALIZED_STRING,
    name: PRODUCT_ATTRIBUTES.ELIGIBLE_FOR_SELLING,
    value: StringBoolean.true,
  };
  let customFiltersForSearch = excludeUnsellableProduct(customParams.languageAndCountry);
  if (searchParams?.customFilters) {
    customFiltersForSearch += searchParams.customFilters;
  }

  const limit = 'limit' in searchParams ? searchParams.limit : RELATED_PRODUCT_LIMIT;

  const searchParamsWithFilter: ProductsSearchParamsExtended = {
    ...searchParams,
    filters: [storeFilter],
    limit,
    isOnStock: customParams.isOnStock,
    customFilters: customFiltersForSearch,
  };
  if (customParams.languagePrefix) {
    searchParamsWithFilter.languagePrefix = customParams.languagePrefix;
  }

  return searchParamsWithFilter;
};
