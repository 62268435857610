import { useProduct } from '@vsf-enterprise/commercetools';
import { computed } from '@nuxtjs/composition-api';
import { defineModule } from '~/helpers/vuex/defineModule/defineModule';
import {
  ProductExtraGuaranteeMutations,
} from '~/types/vuex/ProductExtraGuarantee';
import { MODULE_NAMES } from '~/types/vuex/Vuex';
import { ProductVariantGql } from '~/types/product/ProductVariantGql';
import { useI18n } from '~/composables/useI18n';
import virtualProductSku from '~/constants/virtualProductSku';
import { CUSTOM_QUERIES } from '~/constants/customQueries';
import extendProducts from '~/helpers/product/extendProducts';

export const useProductExtraGuarantee = () => {
  const store = defineModule<typeof MODULE_NAMES.EXTRA_GUARANTEE_MODULE_NAME,
  ProductExtraGuaranteeMutations,
  {},
  {}>(MODULE_NAMES.EXTRA_GUARANTEE_MODULE_NAME);

  const productExtraGuarantee = computed(() => store.state.productExtraGuarantee);

  const load = async ():Promise<void> => {
    if (productExtraGuarantee.value) {
      return;
    }
    const {
      products: extraGuaranteeProducts,
      search: searchExtraGuaranteeProduct,
    } = useProduct<ProductVariantGql>('extra-guarantee-product');

    const { languageAndCountry, countryCode } = useI18n();

    await searchExtraGuaranteeProduct({
      skus: [virtualProductSku],
      customQuery: CUSTOM_QUERIES.PRODUCT_SEARCH.extraGuaranteeQuery,
    });

    const products = extraGuaranteeProducts.value?.data?.products?.results || [];
    const product = extendProducts(products, languageAndCountry.value, countryCode.value, { isPublished: true })?.[0];

    store.mutations.setProduct(product);
  };

  return {
    productExtraGuarantee,
    ...store.actions,
    ...store.mutations,
    ...store.getters,
    load,
  };
};
