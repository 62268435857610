import { useVSFContext } from '@vue-storefront/core';
import { useIntegrations } from '../useIntegrations';
import {
  SECURE_WEBSITE_PROTOCOLE_PREFIX,
  STAGE_PREFIX,
} from '~/constants/http';
import { DEFAULT_REDIRECTION_CODE } from '~/constants/seo';
import { DOMAIN_IE } from '~/constants/domains';
import { i18nToCurrentDomain } from '~/helpers/locales/i18nCurrentDetails';
import { isProduction } from '~/helpers/env/checkEnvironmentType';

/**
 * Composable for the SEO redirection
 */
export const useSeoRedirection = () => {
  const { route, i18n, redirect } = useVSFContext();
  const { $seoRedirection } = useIntegrations();

  const seoRedirection = async () => {
    let redirectHappens = false;
    try {
      if (!process?.server) {
        return;
      }
      const domain = i18nToCurrentDomain(i18n);
      const developmentDomain = `${STAGE_PREFIX}.${DOMAIN_IE}`;
      const currentPath = route.value.path;
      const currentRootDomain = isProduction() ? domain : developmentDomain;
      const sourceUrl = `${SECURE_WEBSITE_PROTOCOLE_PREFIX}${currentRootDomain}${currentPath}`;
      const redirectionResponse = await $seoRedirection.getRedirection(sourceUrl);
      const redirectionData = redirectionResponse?.data;
      if (redirectionData) {
        redirectHappens = true;
        redirect(redirectionData.redirectionCode || DEFAULT_REDIRECTION_CODE, redirectionData.targetURL);
      }
    } catch (_) {
      // expected situation where no redirection exists
    }
    return redirectHappens;
  };

  return {
    seoRedirection,
  };
};
