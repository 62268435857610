import { computed } from '@nuxtjs/composition-api';
import type { ComputedRef } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import { useI18n } from '../useI18n';
import { useRouteExtended } from '../useRouteExtended';
import { ROUTES } from '~/constants/routes';
import {
  getNameForRoute,
  isHomePageSlug,
  isProductPageSlug,
  isSearchPageSlug,
  isValidCmsPage,
} from '~/helpers/routes/getPageInformation';

export const useIsPage = () => {
  const { route: currentRoute } = useVSFContext();
  const { languageAndCountry } = useI18n();
  const { getAdjustedSlug } = useRouteExtended();

  /**
   *  Determine if the current route is the same as the page name arg
   */
  const isOnPage = (pageName: keyof typeof ROUTES): boolean =>
    !!currentRoute.value.name?.startsWith(getNameForRoute(pageName.toString()));
  const isOnProductPage: ComputedRef<boolean> = computed(() =>
    !!(currentRoute.value.params?.slug && isProductPageSlug(currentRoute.value.params?.slug)));
  const isOnHomePage: ComputedRef<boolean> = computed(() => {
    return isHomePageSlug(currentRoute.value.path);
  });
  const isOnInternalCmsPage: ComputedRef<boolean> = computed(() => {
    return isValidCmsPage(currentRoute.value?.params?.slug, languageAndCountry.value);
  });
  const isOnSearchPage: ComputedRef<boolean> = computed(() => isSearchPageSlug(currentRoute.value.path));
  const isOnPaymentPage: ComputedRef<boolean> = computed(() =>
    currentRoute.value.path === getAdjustedSlug(ROUTES.CHECKOUT_PAYMENT));
  const isCheckoutPage: ComputedRef<boolean> = computed(() => isOnPage('CHECKOUT'));
  const isOnAddressesPage: ComputedRef<boolean> = computed(() =>
    currentRoute.value.path === getAdjustedSlug(ROUTES.CHECKOUT_ADDRESSES));
  const isThankYouPage = computed(() => currentRoute.value.path === getAdjustedSlug(ROUTES.THANK_YOU));
  const isCheckoutLogin = computed(() => currentRoute.value.path === getAdjustedSlug(ROUTES.CHECKOUT_LOGIN));
  const isCheckoutCreateAccount = computed(
    () => currentRoute.value.path === getAdjustedSlug(ROUTES.CHECKOUT_CREATE_ACCOUNT),
  );

  return {
    isOnProductPage,
    isOnHomePage,
    isOnSearchPage,
    isOnPage,
    isOnPaymentPage,
    isOnAddressesPage,
    isOnInternalCmsPage,
    isCheckoutPage,
    isThankYouPage,
    isCheckoutLogin,
    isCheckoutCreateAccount,
  };
};
