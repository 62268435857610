import { NuxtAxiosInstance } from '@nuxtjs/axios';
import getRedirection from '~/plugins/integrations/seoRedirection/requests/getRedirection';
import { getAxiosConfig } from '~/helpers/axios/getAxiosConfig';
import { setAxiosInterceptors } from '~/helpers/axios/setAxiosInterceptors';
import { getExpondoMiddlewareUrl } from '~/helpers/env/getMiddlewareUrl';
import { EXPONDO_MIDDLEWARE_ROUTES } from '~/constants/routes';
import { SeoRedirectionIntegration } from '~/types/integrations/seoRedirection/SeoRedirectionIntegration';

export default ({
  $axios,
  $config,
  req,
}: {
  $axios: NuxtAxiosInstance;
  $config: Context['$config'];
  req: Context['req'];
}): SeoRedirectionIntegration => {
  const currentMiddlewareURL = getExpondoMiddlewareUrl({ $config });
  const axios = $axios.create(
    getAxiosConfig(`${currentMiddlewareURL}${EXPONDO_MIDDLEWARE_ROUTES.SEO_REDIRECTION}`),
  );

  setAxiosInterceptors({ axios, req, shouldRejectPromise: true, shouldLogError: false });

  const $seoRedirection: SeoRedirectionIntegration = {
    services: { axios },
    getRedirection: (sourceUrl: string) => {
      throw new Error(`getRedirection not initialized, ${sourceUrl}`);
    },
  };
  $seoRedirection.getRedirection = getRedirection($seoRedirection);
  return $seoRedirection;
};
