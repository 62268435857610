const { HELP_CENTER_TRANSLATION_KEY } = require('./faq');

const ROUTES = {
  HOME: 'HOME',
  HOME_ALTERNATIVE: 'HOME_ALTERNATIVE',
  CATEGORY: 'CATEGORY',
  SEARCH: 'SEARCH',
  SEARCH_CATEGORY: 'SEARCH_CATEGORY',
  PRODUCT_DETAIL: 'PRODUCT_DETAIL',
  LOGIN: 'LOGIN',
  CHECKOUT_LOGIN: 'CHECKOUT_LOGIN',
  CHECKOUT_ADDRESSES: 'CHECKOUT_ADDRESSES',
  CHECKOUT_PAYMENT: 'CHECKOUT_PAYMENT',
  CHECKOUT_RESET_PASSWORD: 'CHECKOUT_RESET_PASSWORD',
  CART: 'CART',
  THANK_YOU: 'THANK_YOU',
  LANDER: 'LANDER',
  PRIVACY: 'PRIVACY',
  LEGAL_NOTICE: 'LEGAL_NOTICE',
  TERMS_CONDITIONS: 'TERMS_CONDITIONS',
  FAQ: 'FAQ',
  PAYMENT_METHODS: 'PAYMENT_METHODS',
  RETURN_POLICY: 'RETURN_POLICY',
  DISPATCH_INFORMATION: 'DISPATCH_INFORMATION',
  BATTERY_REGULATIONS: 'BATTERY_REGULATIONS',
  WARRANTY_CONDITIONS: 'WARRANTY_CONDITIONS',
  COMPLAINTS: 'COMPLAINTS',
  ABOUT_US: 'ABOUT_US',
  BEST_PRICES: 'BEST_PRICES',
  OUR_HISTORY: 'OUR_HISTORY',
  CAREER: 'CAREER',
  MY_ORDERS: 'MY_ORDERS',
  INSPIRATIONS: 'INSPIRATIONS',
  RECALLS: 'RECALLS',
  MERCHANT_INFORMATION: 'MERCHANT_INFORMATION',
  MY_PROFILE: 'MY_PROFILE',
  SHIPPING_DETAILS: 'SHIPPING_DETAILS',
  BILLING_DETAILS: 'BILLING_DETAILS',
  MY_NEWSLETTER: 'MY_NEWSLETTER',
  ORDER_HISTORY: 'ORDER_HISTORY',
  MY_REVIEWS: 'MY_REVIEWS',
  LOG_OUT: 'LOG_OUT',
  MY_ACCOUNT: 'MY_ACCOUNT',
  CREATE_ACCOUNT: 'CREATE_ACCOUNT',
  RESET_PASSWORD: 'RESET_PASSWORD',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CHECK_EMAIL: 'CHECK_EMAIL',
  CHECKOUT_CREATE_ACCOUNT: 'CHECKOUT_CREATE_ACCOUNT',
  CHECKOUT: 'CHECKOUT',
  FONT: 'FONT',
  HIDDEN: 'HIDDEN',
  BUILD: 'BUILD',
  API: 'API',
};

const ROUTES_INFORMATION = {
  [ROUTES.HOME]: {
    NAME: 'home',
    SLUG: '/',
  },
  // HOME_ALTERNATIVE IS CREATED TO CAPTURE TRAFIC WHEN USERS MANUALLY ACCESS TRADITIONAL HOMEPAGE SLUG 'home'
  [ROUTES.HOME_ALTERNATIVE]: {
    NAME: 'Home Alternative',
    SLUG: '/home',
  },
  [ROUTES.SEARCH]: {
    NAME: 'search',
    SLUG: '/search',
  },
  [ROUTES.SEARCH_CATEGORY]: {
    NAME: 'search-category',
    SLUG: '/*',
  },
  [ROUTES.PRODUCT_DETAIL]: {
    NAME: 'product',
    SLUG: '/*',
  },
  [ROUTES.LOGIN]: {
    NAME: 'Login',
    SLUG: '/login',
  },
  [ROUTES.CHECKOUT_LOGIN]: {
    NAME: 'Checkout Login',
    SLUG: '/checkout/login',
  },
  [ROUTES.CHECKOUT_ADDRESSES]: {
    NAME: 'Checkout Addresses',
    SLUG: '/checkout/addresses',
  },
  [ROUTES.CHECKOUT_PAYMENT]: {
    NAME: 'Checkout Payment',
    SLUG: '/checkout/payment',
  },
  [ROUTES.CHECKOUT_RESET_PASSWORD]: {
    NAME: 'Checkout Reset Password',
    SLUG: '/checkout/password-reset',
  },
  [ROUTES.CART]: {
    NAME: 'Cart',
    SLUG: '/checkout/cart',
  },
  [ROUTES.THANK_YOU]: {
    NAME: 'Thank you',
    SLUG: '/checkout/thank-you',
  },
  [ROUTES.PRIVACY]: {
    NAME: 'privacy-data',
    SLUG: '/privacy-data/',
  },
  [ROUTES.LEGAL_NOTICE]: {
    NAME: 'legal-notice',
    SLUG: '/legal-notice/',
  },
  [ROUTES.TERMS_CONDITIONS]: {
    NAME: 'terms-and-conditions',
    SLUG: '/terms-and-conditions/',
  },
  [ROUTES.FAQ]: {
    NAME: 'faq',
    SLUG: HELP_CENTER_TRANSLATION_KEY,
  },
  [ROUTES.PAYMENT_METHODS]: {
    NAME: 'payment',
    SLUG: '/payment/',
  },
  [ROUTES.RETURN_POLICY]: {
    NAME: 'return-policy',
    SLUG: '/return-policy/',
  },
  [ROUTES.DISPATCH_INFORMATION]: {
    NAME: 'dispatch-information',
    SLUG: '/dispatch-information/',
  },
  [ROUTES.BATTERY_REGULATIONS]: {
    NAME: 'battery-regulations',
    SLUG: '/battery-regulations/',
  },
  [ROUTES.WARRANTY_CONDITIONS]: {
    NAME: 'warranty-conditions',
    SLUG: '/warranty-conditions/',
  },
  [ROUTES.COMPLAINTS]: {
    NAME: 'complaints',
    SLUG: '/complaints/',
  },
  [ROUTES.ABOUT_US]: {
    NAME: 'about-us',
    SLUG: 'https://corporate.expondo.com/',
  },
  [ROUTES.BEST_PRICES]: {
    NAME: 'best-prices-guaranteed',
    SLUG: '/best-prices-guaranteed/',
  },
  [ROUTES.OUR_HISTORY]: {
    NAME: 'our-history',
    SLUG: '/our-history/',
  },
  [ROUTES.CAREER]: {
    NAME: 'career',
    SLUG: 'https://corporate.expondo.com/career/',
  },
  [ROUTES.MY_ORDERS]: {
    NAME: 'My Orders',
    SLUG: 'https://myorders.expondo.com/',
  },
  [ROUTES.INSPIRATIONS]: {
    NAME: 'inspirations',
    SLUG: '/inspirations/',
  },
  [ROUTES.RECALLS]: {
    NAME: 'product-recalls',
    SLUG: '/product-recalls/',
  },
  [ROUTES.MERCHANT_INFORMATION]: {
    NAME: 'merchant-information',
    SLUG: '/merchant-information/',
  },
  [ROUTES.MY_PROFILE]: {
    NAME: 'my-profile',
    SLUG: '/my-account/my-profile',
  },
  [ROUTES.SHIPPING_DETAILS]: {
    NAME: 'shipping-details',
    SLUG: '/my-account/shipping-details',
  },
  [ROUTES.BILLING_DETAILS]: {
    NAME: 'billing-details',
    SLUG: '/my-account/billing-details',
  },
  [ROUTES.MY_NEWSLETTER]: {
    NAME: 'my-newsletter',
    SLUG: '/my-account/my-newsletter',
  },
  [ROUTES.ORDER_HISTORY]: {
    NAME: 'order-history',
    SLUG: '/my-account/order-history',
  },
  [ROUTES.MY_REVIEWS]: {
    NAME: 'my-reviews',
    SLUG: '/my-account/my-reviews',
  },
  [ROUTES.LOG_OUT]: {
    NAME: 'log-out',
    SLUG: '/log-out',
  },
  [ROUTES.MY_ACCOUNT]: {
    NAME: 'my-account',
    SLUG: '/my-account',
  },
  [ROUTES.CREATE_ACCOUNT]: {
    NAME: 'create-account',
    SLUG: '/create-account',
  },
  [ROUTES.RESET_PASSWORD]: {
    NAME: 'Reset Password Form',
    SLUG: '/password-reset',
  },
  [ROUTES.CHANGE_PASSWORD]: {
    NAME: 'change-password',
    SLUG: '/password-reset/change-password',
  },
  [ROUTES.CHECK_EMAIL]: {
    NAME: 'Check Email',
    SLUG: '/password-reset/check-email',
  },
  [ROUTES.CHECKOUT_CREATE_ACCOUNT]: {
    NAME: 'Checkout Create Account',
    SLUG: '/checkout/create-account',
  },
  [ROUTES.CHECKOUT]: {
    NAME: 'Checkout',
    SLUG: '/checkout',
  },
  [ROUTES.HIDDEN]: {
    NAME: 'Hidden Routes',
    SLUG: '/.',
  },
  [ROUTES.BUILD]: {
    NAME: 'Build Routes',
    SLUG: '/__',
  },
  [ROUTES.API]: {
    NAME: 'Api Routes',
    SLUG: '/api',
  },
  [ROUTES.FONT]: {
    NAME: 'Font Routes',
    SLUG: '/font',
  },
};

const ROUTE_PARAMS = {
  SEARCH: {
    NEW_PRODUCTS: ['new-arrivals'],
    PRODUCTS_ON_SALE: ['sales'],
  },
};

const ABSOLUTE_ROUTE_START = '/:';
const QUERY_PARAMETER_JOINER = '&';
const QUERY_PARAMETERS_STARTER = '?';
const QUERY_PARAMETER_EQUALS = '=';

const FORBIDDEN_CHARACTERS_IN_ROUTES = ['\'', '"'];

const EXPONDO_MIDDLEWARE_ROUTES = {
  ROOT: '/expondo-middleware',
  SEO_REDIRECTION: 'seo-redirection',
};

module.exports = {
  ROUTES,
  ROUTES_INFORMATION,
  ROUTE_PARAMS,
  ABSOLUTE_ROUTE_START,
  QUERY_PARAMETER_JOINER,
  QUERY_PARAMETERS_STARTER,
  QUERY_PARAMETER_EQUALS,
  FORBIDDEN_CHARACTERS_IN_ROUTES,
  EXPONDO_MIDDLEWARE_ROUTES,
};
