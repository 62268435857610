import { Logger, sharedRef } from '@vue-storefront/core';
import { computed } from '@nuxtjs/composition-api';
import type { ComposableFunctionArgs } from '@vue-storefront/core';
import { useReviewApi } from '~/utils/ct/composables/api/useReviewApi';
import type { UseReviewData } from '~/utils/ct';
import type { UpdatePaginationPageParams } from '~/types/pagination';
import { errorToString } from '~/helpers/error/errorToString';

export function useReview(id: string) {
  const { search: apiSearch } = useReviewApi();
  const reviews = sharedRef<UseReviewData | undefined>(undefined, `useReviews-reviews-${id}`);
  const loading = sharedRef(false, `useReviews-loading-${id}`);

  const error = sharedRef<{
    search: null | string,
  }>({
    search: null,
  }, `useReviews-error-${id}`);

  async function search(searchParams: ComposableFunctionArgs<UpdatePaginationPageParams>) {
    Logger.debug(`useReview/${id}/search`, searchParams);
    try {
      loading.value = true;
      reviews.value = await apiSearch({ id, ...searchParams });
      error.value.search = null;
    } catch (err: unknown) {
      error.value.search = errorToString(err);
      Logger.error(`useReview/${id}/search`, err);
    } finally {
      loading.value = false;
    }
  }

  return {
    search,
    reviews: computed(() => reviews.value),
    loading: computed(() => loading.value),
    error: computed(() => error.value),
  };
}
