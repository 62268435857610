






























import { computed, defineComponent, useRouter } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { useNavigationState } from '~/composables';
import ImgView from '~/components/atoms/ImgView/ImgView.vue';
import getCategoryIcon from '~/helpers/category/getCategoryIcon';
import type { NavBarCategory } from '~/types/category/NavBarCategory';
import { SECOND_HAND_CATEGORY_KEY } from '~/constants/categoriesKeys';

export default defineComponent({
  name: 'NavMainBarCategory',
  components: {
    ImgView,
  },
  props: {
    currentItem: {
      type: Object as PropType<NavBarCategory>,
      default: () => {
        return {};
      },
    },
    bold: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'text-EXPONDO-primary',
    },
  },
  setup(props) {
    const {
      toggleNavigation,
      isNavigationOpen,
      setFirstLevelCategory,
      currentFirstLevelCategorySlug,
    } = useNavigationState();
    const router = useRouter();

    const itemClick = () => {
      if (props.currentItem.link) {
        router.push(props.currentItem.link);
        isNavigationOpen.value && toggleNavigation();
        return;
      }

      const slug = props.currentItem.slug;
      if (!isNavigationOpen.value || slug === currentFirstLevelCategorySlug.value) {
        toggleNavigation();
      }
      setFirstLevelCategory(slug);
    };

    const iconUrl = computed<string | null>(() => {
      return getCategoryIcon(props.currentItem.key);
    });

    const isBold = computed<boolean>(() => {
      return props.bold || props.currentItem.key === SECOND_HAND_CATEGORY_KEY;
    });

    return {
      itemClick,
      iconUrl,
      isBold,
    };
  },
});
