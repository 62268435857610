module.exports = {
  MAX_SINGLE_PRODUCT_CART_QUANTITY: 1000,
  GRAHAM_REVIEW_ENDPOINT: 'https://expondo-graham-stage.azurewebsites.net/api/review',
  IMAGES_360_URL: 'https://assets-stage.expondo.com/asset/360_photos',
  typesenseHost: 'tokgpi1d5804zjxmp-1.a1.typesense.net',
  BLOOMREACH_API: {
    DOMAIN_ID: 'd1a36480-2003-11ea-a284-7641af141315',
    SIMILAR_PRODUCTS_ID: '649ace971e6d400f339269d2',
    LAST_SEEN_ID: '6492ce52fd90da11dbc0aaac',
    CART_RECOMMENDATION_ID: '64feb92c83732290d58b33c8',
  },
  SALESFORCE_WEBFORM: {
    SUBMIT_URL: 'https://expondogmbh--uat.sandbox.my.salesforce.com/servlet/servlet.WebToCase',
    ORGANISATION_ID: '00DKJ000000GycF',
  },
};
