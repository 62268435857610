import { computed } from '@nuxtjs/composition-api';
import { addToCartSimplifiedObject } from '~/helpers/cart/cartActions';
import {
  useAddToCart,
  useExtraGuarantee,
  useRouteOfAddToCart,
  useAddToCartTagManager,
} from '~/composables';
import { isTestProduct } from '~/helpers/product/isTestProduct';

type useAddToCartQuantityParams = {
  sku: string;
  slug: string;
  productPrice: number;
  productName: string;
  productImage: string;
  isMainProduct: boolean;
  addToCartText: string;
  isWarrantySection: boolean;
};

type AddToCartParams = {
  isWithExtraGuarantee?: boolean;
  shouldOpenCart?: boolean;
}

type AddToCartFunction = (quantity: number, params?: AddToCartParams) => void

type useAddToCartQuantityReturn = {
  addQuantity: AddToCartFunction;
};

export const useAddToCartQuantity = ({
  sku,
  slug,
  productPrice,
  productName,
  productImage,
  isMainProduct,
  isWarrantySection,
  addToCartText,
}: useAddToCartQuantityParams): useAddToCartQuantityReturn => {
  const { setExtraGuaranteeInfo } = useExtraGuarantee();
  const { addToCart } = useAddToCart(slug, sku);
  const { getAddToCartButtonLocation } = useRouteOfAddToCart();
  const { triggerTags } = useAddToCartTagManager({
    slug,
    addToCartText,
  });
  const testProduct = computed(() => isTestProduct(sku));
  const addQuantity: AddToCartFunction = async (quantity, { isWithExtraGuarantee, shouldOpenCart } = {}) => {
    if (testProduct.value) {
      return;
    }
    setExtraGuaranteeInfo({ price: productPrice, name: productName, image: productImage, sku });
    const triggeredIn = getAddToCartButtonLocation({
      isMainProduct,
      isWarrantySection,
    });
    await addToCart(
      { product: addToCartSimplifiedObject(sku), quantity, isWithExtraGuarantee, triggeredIn, shouldOpenCart });
    triggerTags({
      quantity,
      triggeredIn,
      shouldOpenCart,
    });
  };

  return {
    addQuantity,
  };
};
